import { getCaptchaInfo } from './api/captcha';
import CryptoJS from 'crypto-js';
import { Captcha } from './index';

// https://docs.geetest.com/sensebot/apirefer/api/web#lang
export const langMap = {
  'en-US': 'en',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'id-ID': 'id',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'ru-RU': 'ru',
  'zh-CN': 'zh-cn',
  'zh-TW': 'zh-tw',
  'de-DE': 'de'
};

/** 极验 */
const geetestCaptcha = {
  key: 'geecaptcha',
  src: 'https://static.geetest.com/static/js/gt.0.4.9.js',
  async init(context: Captcha, options, callback) {
    const params = {
      type: 'geetest',
      // login_name: ''
      login_name: CryptoJS.MD5(context.guid).toString()
    };
    const data: any = await getCaptchaInfo(params);
    if (!data) {
      throw new Error('geetest error offline');
      return;
    }
    return new Promise((resolve) => {
      (<any>window)?.initGeetest(
        {
          // captchaId: 'baee87eb24c772b9321214204dd04d1e',
          gt: data.gt,
          challenge: data.challenge,
          offline: !data.success,
          new_captcha: data.new_captcha,
          lang: langMap[context.lang] || 'en',
          product: 'bind',
          hideSuccess: true,
          hideClose: true,
          hideRefresh: true
        },
        function (captchaObj) {
          context.captcha = captchaObj;
          captchaObj.onSuccess(function () {
            const validateRes = captchaObj.getValidate();
            callback({ ...validateRes });
          });

          captchaObj.onError(function (err) {
            const code = err['error_code'].split('_')[1];
            console.log('geetest Error', code, err);
          });
          resolve(true);
        }
      );
    });
  },

  show(context: Captcha) {
    try {
      context.captcha.verify();
    } catch (e) {
      console.error('Geetest Show', e);
    }
  }
};

export default geetestCaptcha;
