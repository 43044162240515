import { parse, stringifyUrl, StringifiableRecord } from 'query-string';

// fill object query to url
export function fillUrlParams(url: string, query: StringifiableRecord) {
  return stringifyUrl({ url, query });
}

/**
 * 
 * console.log(location.search);
//=> '?foo=bar'

const parsed = queryString.parse(location.search);
console.log(parsed);
//=> {foo: 'bar'}

console.log(location.hash);
//=> '#token=bada55cafe'

const parsedHash = queryString.parse(location.hash);
console.log(parsedHash);
//=> {token: 'bada55cafe'}
 * @param url 
 * @returns 
 */
export function parseUrl(url: string) {
  return parse(url);
}

export function getCurrentUrlParameters() {
  if (typeof window === 'undefined') {
    return {};
  }
  const params = {};
  const url = new URL(window?.location?.href); // 获取当前页面的 URL
  const searchParams = new URLSearchParams(url.search);

  for (const [key, value] of searchParams.entries()) {
    params[key] = value; // 将每个参数存入对象
  }

  return params;
}
